import './EconsultInvestigatorMeetingModal.less'

import classNames from 'classnames'
import React, { useContext, useEffect, useMemo, useState } from 'react'

import notification from '../../../../assets/sounds/notification.mp3'
import { useScopedIntl } from '../../../../hooks'
import { DatacModal, DatacTitle } from '../../../common'
import { EconsultEndMeetingModal } from '../EconsultEndMeetingModal'
import { EconsultMeeting } from '../EconsultMeeting'
import { EconsultMeetingContext, MeetingStateDataTypes } from '../EconsultMeetingContext'
import { EconsultWaitingRoom } from '../EconsultWaitingRoom'

export const EconsultInvestigatorMeetingModal: React.FC = () => {
  const eConsultIntl = useScopedIntl('studies.econsult')

  const [minimizedPosition, setMinimizedPosition] = useState(null)

  const {
    isInvestigatorModalOpened,
    joinedToMeeting,
    isConfirmModalOpened,
    meetingStateDispatch,
    handleShowEndMeetingConfirmModal,
    handleConfirmEndMeeting,
    room,
    isAcquiringLocalTracks,
    isConnecting,
    subjectReady
  } = useContext(EconsultMeetingContext)
  const NotificationSound = new Audio(notification)

  const showWaitingRoom = useMemo(() => {
    return room && room.localParticipant && !isConnecting && !isAcquiringLocalTracks
  }, [room, isConnecting, isAcquiringLocalTracks])

  useEffect(() => {
    if (subjectReady && showWaitingRoom) {
      NotificationSound.play()
    }
  }, [subjectReady])

  const WaitingRoom = () =>
    showWaitingRoom ? (
      <EconsultWaitingRoom minimizedPosition={minimizedPosition} setMinimizedPosition={setMinimizedPosition} />
    ) : (
      <DatacTitle type="h3" className="econsult-modal__connecting">
        {eConsultIntl('connecting')}
      </DatacTitle>
    )

  return (
    <DatacModal
      title={eConsultIntl('modal_title')}
      isOpened={isInvestigatorModalOpened}
      onClose={handleShowEndMeetingConfirmModal}
      loading={false}
      className={classNames('econsult-modal', { 'econsult-modal--minimized': minimizedPosition })}
      wrapClassName={classNames('econsult-modal', {
        'econsult-modal__wrapper--minimized': minimizedPosition,
        'econsult-modal__wrapper--minimized--left': minimizedPosition === 'left',
        'econsult-modal__wrapper--minimized--right': minimizedPosition === 'right'
      })}
      isMaskHidden={minimizedPosition}
    >
      {joinedToMeeting ? (
        <EconsultMeeting minimizedPosition={minimizedPosition} setMinimizedPosition={setMinimizedPosition} />
      ) : (
        <WaitingRoom />
      )}
      <EconsultEndMeetingModal
        content={eConsultIntl('disconnect_warning_investigator')}
        isOpened={isConfirmModalOpened}
        onClose={() =>
          meetingStateDispatch({ type: MeetingStateDataTypes.SET_IS_CONFIRM_END_MODAL_OPENED, payload: false })
        }
        onSubmit={handleConfirmEndMeeting}
      />
    </DatacModal>
  )
}
